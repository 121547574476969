<style>
.ag-header-cell.ag-header-cell-sortable {
    background-color: #142b73;
    color: white;
}
.ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover, .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing, .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover, .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing, .ag-theme-material .ag-header-group-cell:first-of-type:hover, .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing, .ag-theme-material .ag-header-cell:first-of-type:hover, .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing {
    color: #292828;
}
.ag-header-group-cell.ag-header-group-cell-with-group{
  background-color: #edf1fb;
}
</style>
<template>
  <div>
    <b-modal v-model="modalShow" title="Confirmación" size="sm" centered>
      ¿Está seguro que desea eliminar el pedido de {{dealToDelete.name}}?
      <b-form-group class="mt-1" v-slot="{ ariaDescribedby }">
        <b-form-radio v-if="dealToDelete.remisionID" v-model="modalSelected" :aria-describedby="ariaDescribedby" name="some-radios" value=false>Eliminar solo de Sistevot</b-form-radio>
        <b-form-radio v-if="dealToDelete.remisionID" v-model="modalSelected" :aria-describedby="ariaDescribedby" name="some-radios" value=true>Eliminar también remisión #{{dealToDelete.number}} de alegra</b-form-radio>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="modalShow=false">
          No
        </b-button>
        <b-button v-if="modalSelected === 'true'" variant="success" size="sm" class="float-right" @click="deleteDeal(true)">
          Sí
        </b-button>
        <b-button v-else variant="success" size="sm" class="float-right ml-2" @click="deleteDeal(false)">
          Sí
        </b-button>
      </template>
    </b-modal>
    <iq-card>
      <template v-slot:headerAction>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="6">
            <div class="d-flex justify-content-start mb-2" >
              <b-button @click="filtrarPagos" v-if="filtroPagado" :disabled="quitar"  variant="success" class="mx-2 my-1">
                Filtrar Completados
                <i class="ri-check-double-line"></i>
              </b-button>
              <b-button @click="filtrarPendientes" v-if="filtroPendiente" :disabled="quitar" class="mx-2 my-1 button-filtro-pendiente">
                Filtrar Pendientes
              </b-button>
              <b-button @click="quitarFiltros" v-if="quitar"  variant="primary" class="mx-2 my-1" >
                Quitar filtro
              </b-button>
              <div v-if="!quitarFiltros">
                <p v-if="filtroPagado"> Registros con estado de pago pagado.</p>
                <p v-if="filtroPendiente"> Registros con estado de pago pendiente.</p>
              </div>
              <p class="h6 mt-3 mb-2 ml-5">Registros <span style="font-weight: bold;" > {{mainTableDataGrid.length}}</span></p>
            </div>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end mb-2">
            <b-button variant="light" class="mr-2 mx-2 my-1" @click="autoSizeAll(false)">
              Ajustar columnas
            </b-button>
            <b-button v-if="canDelete" variant="danger" class="mr-2 mx-2 my-1" @click="modalShow=!modalShow">
              Eliminar
            </b-button>
            <b-button  class="mx-2 my-1" @click="filterItem(), clear(), modal = !modal">
              Crear nuevo pedido
            </b-button>
          </b-col>
        </b-row>
        <!-- SABANA PRINCIPAL -->
        <div class="mt-10"  >
            <ag-grid-vue
              id="ag-grid"
              class="ag-theme-material border height-500"
              :gridOptions="gridOptions"
              :defaultColDef="defaultColDef"
              :defaultColGroupDef="defaultColGroupDef"
              :columnDefs="mainTableHeaderGrid"
              :headerHeight="headerHeight"
              :floatingFiltersHeight="floatingFiltersHeight"
              :enableCellTextSelection="true"
              :rowData="mainTableDataGrid"
              :floatingFilter="true"
              :modules="modules"
              rowSelection="single"
              :resizable="true"
              :pagination=false
              :paginationPageSize="paginationPageSize"
              @row-selected="onRowSelectedMain"
              @selection-changed="onSelectionChangedMain"
              @rowDoubleClicked="onRowClicked2"
              style="width: 100%; height: 70vh;"
            ></ag-grid-vue>
          <!-- <b-table :items="sale.items" ></b-table> -->
          </div>
         <div>
            <b-button class="mx-2 my-3 button-filtro-excel" >
              <download-excel :data="mainTableDataGrid" :fields="json_fields" name="reporte">
                Descargar Resultados <i class="far fa-file-excel"></i>
              </download-excel>
            </b-button>
          </div>
      </template>
    </iq-card>
    <!-- MODAL DE CONFIRMACIÓN PEDIDO -->
    <b-modal v-model="modalShow2" title="Confirmación" size="sm" centered>
      <p>¿Está seguro que desea guardar el pedido de {{data.dataClient.coproperty}} por un valor de {{currencyFormatterCop(data.billingData.totalValue)}}?</p>
      <b-form-group class="mt-1" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="modalSelected2" :aria-describedby="ariaDescribedby" name="radios" value=false>Guardar solo en Sistevot</b-form-radio>
        <b-form-radio v-model="modalSelected2" :aria-describedby="ariaDescribedby" name="radios" value=true>Guardar también remisión en Alegra</b-form-radio>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="modalShow=false">
          No
        </b-button>
        <b-button v-if="modalSelected2 === 'true'" variant="success" size="sm" class="float-right" @click="saveDealRemision(true)">
          Sí
        </b-button>
        <b-button v-else variant="success" size="sm" class="float-right ml-2" @click="saveDealRemision(false)">
          Sí
        </b-button>
      </template>
    </b-modal>
    <!-- MODAL DE ACTUALIZACIÓN DE PEDIDO-->
    <b-modal v-model="modalShow3" title="Confirmación" size="sm" centered>
      <p>¿Está seguro que desea actualizar el pedido de {{data.dataClient.coproperty}} por un valor de {{currencyFormatterCop(data.billingData.totalValue)}}?</p>
      <b-form-group v-if="data.billingData.remisionID > 1" class="mt-1" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="modalSelected3" :aria-describedby="ariaDescribedby" name="radios" value=false>Actualizar solo en Sistevot</b-form-radio>
        <b-form-radio v-model="modalSelected3" :aria-describedby="ariaDescribedby" name="radios" value=true>Actualizar también remisión # {{data.billingData.invoiceRemision}} en Alegra</b-form-radio>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="modalShow=false">
          No
        </b-button>
        <b-button v-if="modalSelected3 === 'true'" variant="success" size="sm" class="float-right" @click="updateDealRemision(true)">
          Sí
        </b-button>
        <b-button v-else variant="success" size="sm" class="float-right ml-2" @click="updateDealRemision(false)">
          Sí
        </b-button>
      </template>
    </b-modal>
    <!-- MODAL -->
    <b-modal size="xl" v-model="modal" no-close-on-backdrop @close="clear()" >
      <iq-card>
        <template v-slot:body>
          <h3 class="mb-3">Nuevo pedido</h3>
          <div class="d-flex justify-content-end">
            <b-button @click.prevent="clear()">limpiar</b-button>
          </div>
        <section>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(handleOk)">
              <!-- INFORMACION GENERAL DEl USUARIO -->
              <div>
                <label class="ml-2" for="input-medium" @click="collapse1 = !collapse1">
                  DATOS DEL CLIENTE
                  <i v-if="!collapse1" class="ri-arrow-drop-down-line ml-3"></i>
                  <i v-else class="ri-arrow-drop-up-line ml-3"></i>
                </label>
                <b-collapse v-model="collapse1" id="collapse-data-client">
                  <!-- CAMPO Y BOTON PARA BUSCAR POR NIT  -->
                  <div>
                    <b-row>
                      <b-col col lg="6" sm="12" class="mb-3">
                      <ValidationObserver v-slot="{ handleSubmit }" ref="observer3">
                        <form @submit.prevent="handleSubmit(filterClient)">
                          <label class="ml-2" for="input-small">Buscar por NIT</label>
                          <ValidationProvider name="Valor de" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="nit" type="number" id="nitSearchFormControl" placeholder="Ingrese NIT sin digito de verficación" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <b-button variant="primary" text class="ml-3" type="submit">
                                Buscar
                                <i class="ri-search-eye-line ml-2" ></i>
                              </b-button>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                            </ValidationProvider>
                          </form>
                        </ValidationObserver>
                      </b-col>
                    </b-row>
                  </div>
                <!-- FIN DE CAMPO Y BOTON PARA BUSCAR POR NIT -->
                  <ValidationObserver  v-slot="{ handleSubmit }" ref="observerCollapse1">
                    <form @submit.prevent="handleSubmit(next(1))">
                      <b-row>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Tipo de doc:</label>
                          <ValidationProvider name="typeDocument" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-select v-model="data.dataClient.type" :options="typeDocument" type="text" id="docTypeFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-select>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Documento:</label>
                          <ValidationProvider name="nitClient" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.nit" type="text" id="nitClientFormControl" placeholder="Ej: 90153061" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">DV:</label>
                          <ValidationProvider name="dv" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.dv" type="text" id="dvFormControl" placeholder="Ej: 4" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">ID Alegra:</label>
                          <ValidationProvider name="dv" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.id" type="text" id="idFormControl" placeholder="Ej: 256" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Unidad:</label>
                          <ValidationProvider name="coproperty" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.coproperty" type="text" id="copropertyFormControl" placeholder="Ingrese el nombre de la unidad" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Nombre del administrador:</label>
                          <ValidationProvider name="name admin " rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.nameAdmin" type="text" id="nameAdminFormControl" placeholder="Ingrese el nombre del admin" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Email:</label>
                          <ValidationProvider name="email" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm">
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.email" type="text" id="emailFormControl" placeholder="Ej: info@clasealpha.com" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Numero de contacto:</label>
                          <ValidationProvider name="number contact " v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.phonePrimary" type="text" id="phoneFormControl" placeholder="Ingrese numero de telefono" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">País:</label>
                          <ValidationProvider name="country" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.country" type="text" id="countryFormControl" placeholder="Colombia" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Departamento:</label>
                          <ValidationProvider name="stateCity" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.state" type="text" id="stateCityFormControl" placeholder="Ej: Antioquia" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Ciudad:</label>
                          <ValidationProvider name="city" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="data.dataClient.city" type="text" id="cityFormControl" placeholder="Ej: Envigado" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-input>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Direccion:</label>
                          <ValidationProvider name="address" v-slot="{ errors }" >
                          <b-input-group size="sm" >
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-input v-model="data.dataClient.address" type="text" id="addressFormControl" placeholder="Ingrese la direccion" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-input>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Barrio:</label>
                          <ValidationProvider name="address" v-slot="{ errors }" >
                          <b-input-group size="sm" >
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-input v-model="data.dataClient.neighborhood" type="text" id="neighborhoodFormControl" placeholder="Nombre del barrio" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-input>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Sector:</label>
                          <ValidationProvider name="sector" v-slot="{ errors }" >
                          <b-input-group size="sm" >
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-input v-model="data.dataClient.sector" type="text" id="sectorFormControl" placeholder="Describa el sector" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-input>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Tipo de cliente:</label>
                          <ValidationProvider name="client" v-slot="{ errors }" >
                          <b-input-group size="sm" >
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-select v-model="data.dataClient.client" :options="optionsClient" type="text" id="typeClientFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-select>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- CANAL DE COMUNICACIÓN -->
                        <b-col cols="6" class="mb-3">
                          <label class="ml-2" for="input-small">Canal de comunicación :</label>
                          <ValidationProvider name="client" rules="required" v-slot="{ errors }" >
                            <b-input-group size="sm" >
                              <template #prepend>
                                <b-input-group-text >
                                  <i class="ri-bookmark-2-line"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-select v-model="sale.communicationChannel" :options="optionsChanel" type="text" id="communicationChannelFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                              </b-form-select>
                              <div class="invalid-feedback ml-2">
                                  <span>{{ errors[0] }}</span>
                              </div>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="12">
                          <div class="d-flex justify-content-end">
                            <b-button v-if="nitFound" @click.prevent="updateDataClient" variant="primary" class="mr-2">Actualizar cliente</b-button>
                            <b-button v-if="!nitFound" @click.prevent="createDataClient" variant="primary" class="mr-2">Crear cliente</b-button>
                            <b-button @click.prevent="next(1)">Siguiente</b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </form>
                  </ValidationObserver>
                </b-collapse>
              </div>
              <hr>
              <!-- INFORMACIONDE LOS PRODUCTOS -->
              <ValidationObserver v-slot="{ handleSubmit1 }" ref="observer2">
                <div>
                  <label class="ml-2" for="input-medium" @click="collapse2 = !collapse2">
                    PRODUCTOS
                    <i v-if="!collapse2" class="ri-arrow-drop-down-line ml-3"></i>
                    <i v-else class="ri-arrow-drop-up-line ml-3"></i>
                  </label>
                  <form @submit.prevent="handleSubmit1(addItem)">
                    <div>
                      <b-collapse v-model="collapse2" id="collapse-data-client">
                        <b-row class="mb-5">
                          <b-col cols="9">
                            <h4>Filtro de productos</h4>
                            <a class="mb-2">Filtra por nombre, precio o id, haz clic en el item deseado para que se cargue en pedido</a>
                            <!-- TABLA DE PRODUCTOS A ELEGIR -->
                            <ag-grid-vue
                                id="ag-grid"
                                class="ag-theme-material border height-500"
                                :defaultColDef="defaultColDef"
                                :columnDefs="tableHeaderGrid1"
                                :rowData="tableDataGrid1"
                                :headerHeight="headerHeight"
                                :enableCellTextSelection="true"
                                :floatingFilter="true"
                                :modules="modules"
                                rowSelection="multiple"
                                :resizable="true"
                                :pagination=true
                                :paginationPageSize="paginationPageSize1"
                                style="width: 100%; height: 500px;"
                                @rowClicked="onRowClicked"
                              ></ag-grid-vue>
                          </b-col>
                          <b-col cols="3">
                            <b-row align-v="center" style="display:block;">
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">ID:</label>
                                <ValidationProvider name="item name " rules="required" v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.id" type="number" id="idFormControl" placeholder="Ej: 125" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">% IVA</label>
                                <ValidationProvider name="item name " rules="required" v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.iva" type="number" id="ivaFormControl" placeholder="Ej: 19" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">Nombre:</label>
                                <ValidationProvider name="item name " rules="required" v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.name" id="idFormControl" placeholder="Ej: Gato Negro" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">Descripción</label>
                                <ValidationProvider name="item name " v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-bookmark-2-line"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.description" id="descriptionFormControl" placeholder="Ej: Incluye..." :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">Precio:</label>
                                <ValidationProvider name="Price item " rules="required|numeric|min:1" v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-coin-fill"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.price" type="number" id="priceFormControl" placeholder="Ej:40000" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col cols="12" class="mb-3">
                                <label class="ml-2" for="input-small">Cantidad:</label>
                                <ValidationProvider name="item quantity " rules="required|numeric|min:1" v-slot="{ errors }" >
                                  <b-input-group size="sm" >
                                    <template #prepend>
                                      <b-input-group-text >
                                        <i class="ri-shopping-cart-fill"></i>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input v-model="item.quantity" type="number" id="quantityFormControl" placeholder="Enter the name" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                    </b-form-input>
                                    <div class="invalid-feedback ml-2">
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                  </b-input-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col cols="12">
                                <b-button variant="primary" @click.prevent="addItem" class="float-right">Agregar  Item</b-button>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <!-- TABLA DE PRODUCTOS DINAMICA CON EDICION -->
                        <div class="mt-10" id="pedidos" >
                          <h4>Pedido</h4>
                          <a>Los ítem añadidos en la siguiente tabla serán los creados en el pedido</a>
                          <div class="d-flex justify-content-end mb-2">
                            <b-button size="sm" @click.prevent="clearProducts()" variant="primary">limpiar</b-button>
                            <b-button size="sm" class="ml-2" @click.prevent="clearProductssingle()">eliminar seleccionado</b-button>
                          </div>
                          <ag-grid-vue
                              id="ag-grid"
                              class="ag-theme-material border height-500"
                              :columnDefs="tableHeaderGrid"
                              :headerHeight="headerHeight"
                              :rowData="tableDataGrid"
                              :floatingFilter="true"
                              :modules="modules"
                              :enableCellTextSelection="true"
                              rowSelection="single"
                              :resizable="true"
                              :editable="true"
                              :pagination=true
                              :paginationPageSize="paginationPageSize"
                              @row-selected="onRowSelected"
                              @selection-changed="onSelectionChanged"
                              style="width: 100%; height: 60vh;"
                            ></ag-grid-vue>
                          <!-- <b-table :items="sale.items" ></b-table> -->
                        </div>
                        <div>
                          <div class="d-flex justify-content-end">
                            <b-button class="mt-3" @click.prevent="next(2)">Siguiente</b-button>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </form>
                </div>
                <hr>
              </ValidationObserver>
              <!-- INFORMACION DE LOS PRECIOS -->
              <div>
                <label @click="collapse3 = !collapse3" class="ml-2" for="input-medium">
                  INFORMACION DE FACTURACIÓN
                  <i v-if="!collapse3" class="ri-arrow-drop-down-line ml-3"></i>
                  <i v-else class="ri-arrow-drop-up-line ml-3"></i>
                </label>
                <b-collapse v-model="collapse3" id="collapse-information-prices">
                  <div>
                    <b-row>
                      <b-col cols="5" class="mb-3">
                        <label class="ml-2" for="input-small">Valor total:</label>
                        <ValidationProvider name="total value " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.billingData.totalValue" type="number" id="totalValueFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="5" class="mb-3">
                        <label class="ml-2" for="input-small">Valor Anticipo:</label>
                        <ValidationProvider name="advance payment " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.billingData.advancePayment" type="number" id="advancePaymentFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="5" class="mb-3">
                        <label class="ml-2" for="input-small">Estado del pago:</label>
                        <ValidationProvider name="advance payment " rules="required" v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-select v-model="data.billingData.status" :options="optionsPayment" id="statusPaymentFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="5" class="mb-3">
                        <label class="ml-2" for="input-small">ID remisión Alegra:</label>
                        <ValidationProvider name="remisionid" v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.billingData.remisionID" type="number" id="remisionIDFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="5" class="mb-3">
                        <label class="ml-2" for="input-small"># Factura o remisión:</label>
                        <ValidationProvider name="invoiceRemision" v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.billingData.invoiceRemision" type="text" id="totalValueFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button @click.prevent="next(3)">Siguiente</b-button>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <hr>
              <!-- LOGÍSTICA -->
              <div>
                <label @click="collapse4 = !collapse4" class="ml-2" for="input-medium">
                  LOGÍSTICA
                  <i v-if="!collapse4" class="ri-arrow-drop-down-line ml-3"></i>
                  <i v-else class="ri-arrow-drop-up-line ml-3"></i>
                </label>
                <b-collapse v-model="collapse4" id="collapse-information-prices">
                  <div>
                    <b-row>
                      <b-col cols="4" class="mb-3">
                        <label class="ml-2" for="input-small">Fecha de pedido</label>
                        <ValidationProvider name="date" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template>
                              <div>
                                <b-form-datepicker id="datepicker" v-model="sale.date" class="mb-2"></b-form-datepicker>
                              </div>
                            </template>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="4" class="mb-3">
                        <label class="ml-2" for="input-small">Fecha de montaje</label>
                        <ValidationProvider name="mountingdate" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template>
                              <div>
                                <b-form-datepicker id="mountingdatepicker" v-model="sale.mountingDate" class="mb-2"></b-form-datepicker>
                              </div>
                            </template>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="4" class="mb-3">
                        <label class="ml-2" for="input-small">Fecha de vencimiento</label>
                        <ValidationProvider name="duedate" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template>
                              <div>
                                <b-form-datepicker id="duedatepicker" v-model="sale.dueDate" class="mb-2"></b-form-datepicker>
                              </div>
                            </template>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Conductor</label>
                        <ValidationProvider name="operario1" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-select v-model="data.logistic.team.operator1" :options="optionsTeam" id="teamFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Decorador</label>
                        <ValidationProvider name="operario1" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-select v-model="data.logistic.team.operator2" :options="optionsTeam" id="teamFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Agendado por:</label>
                        <ValidationProvider name="agendado" v-slot="{ errors }" >
                          <b-input-group size="sm">
                            <template #prepend>
                              <b-input-group-text >
                                <i class="ri-bookmark-2-line"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-select v-model="data.logistic.scheduleBy" :options="optionsInnerTeam" id="InnerTeamFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                            <div class="invalid-feedback ml-2">
                                <span>{{ errors[0] }}</span>
                            </div>
                          </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Montaje :</label>
                        <ValidationProvider name="mounting " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.logistic.mounting" type="number" id="mountingFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Desmontaje :</label>
                        <ValidationProvider name="dismounting " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.logistic.dismounting" type="number" id="dismountingFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Rotacion :</label>
                        <ValidationProvider name="rotation " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.logistic.rotation" type="number" id="rotationFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Figuras :</label>
                        <ValidationProvider name="figures " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="data.logistic.figures" type="number" id="figuresFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Heno :</label>
                        <ValidationProvider name="figures " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="sale.heno" type="number" id="henoFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" lg="2" class="mb-3">
                        <label class="ml-2" for="input-small">Heno Adicional:</label>
                        <ValidationProvider name="figures " v-slot="{ errors }" >
                        <b-input-group size="sm" >
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-input v-model="sale.henoAdicional" type="number" id="henoAdFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-input>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Separado</label>
                        <ValidationProvider name="sector" v-slot="{ errors }" >
                        <b-input-group size="sm">
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-select v-model="data.logistic.separated" :options="optionsSeparated" id="separatedFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                      </ValidationProvider>
                    </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Observaciones :</label>
                        <ValidationProvider name="observations " v-slot="{ errors }" >
                        <b-input-group size="sm">
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-textarea v-model="data.logistic.observations" type="text" id="observationsFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-textarea>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="ml-2" for="input-small">Estado</label>
                        <ValidationProvider name="finalStatus" v-slot="{ errors }" >
                        <b-input-group size="sm">
                          <template #prepend>
                            <b-input-group-text >
                              <i class="ri-bookmark-2-line"></i>
                            </b-input-group-text>
                          </template>
                          <b-form-select v-model="data.logistic.status" :options="optionsStatus" id="statusFormControl" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-select>
                          <div class="invalid-feedback ml-2">
                              <span>{{ errors[0] }}</span>
                          </div>
                        </b-input-group>
                      </ValidationProvider>
                    </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </div>
              <hr>
            </form>
          </ValidationObserver>
        </section>
        <section class="mt-3">
        </section>
      </template>
      </iq-card>
      <template #modal-footer>
      <div class="w-100">
        <b-row>
          <b-col cols="4">
            <div class="d-flex justify-content-start mt-2">
              <b-button
                variant="danger"
                size="sm"
                @click="modal=false, clear()"
              >
                Cancelar pedido
              </b-button>
            </div>
          </b-col>
          <b-col cols="8">
            <div class="d-flex justify-content-end">
              <b-button size="sm" variant="primary" v-if="sale.items.length > 0 && !aaaid" @click.prevent="modalShow2=!modalShow2" class="mr-2 mt-2">Guardar pedido</b-button>
              <!-- Esto se podría borrar si todo va bien
              <b-button class="mt-2" @click.prevent="saveDealRemision" v-if="sale.items.length > 0 && !aaaid">
                Guardar pedido y crear remisión
              </b-button>
              -->
              <b-button v-if="aaaid" @click.prevent="modalShow3=!modalShow3" variant="primary" class="mr-2 mt-2">Actualizar pedido</b-button>
              <!-- Esto se podría borrar si todo va bien
              <b-button class="mt-2" @click.prevent="updateDealRemision" v-if="aaaid">
                Actualizar pedido y remision
              </b-button>
              -->
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import axios from 'axios'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='
Vue.component('downloadExcel', JsonExcel)

function currencyFormatter (params) {
  return '\x24' + formatNumber(params.value)
}

function formatNumber (number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export default {
  name: 'ClaseAlpha',
  mounted () {
    core.index()
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi

    // this.axiosTest()
  },
  beforeMount () {
    this.gridOptions = {
      rowSelection: 'single'
    }
    this.tableHeaderGrid = [
      { headerName: 'Id', field: 'id', sortable: true, filter: true, checkboxSelection: true, width: '100' },
      { headerName: 'Nombre ', field: 'name', sortable: true, filter: true },
      { headerName: 'Precio', field: 'price', sortable: true, filter: true },
      { headerName: 'Descripción', field: 'description', sortable: true, filter: true },
      { headerName: 'Cantidad', field: 'quantity', sortable: true, filter: true },
      { headerName: 'IVA', field: 'iva', sortable: true, filter: true }
    ]
    this.tableHeaderGrid1 = [
      { headerName: 'Id', field: 'id', sortable: true, filter: true, width: '100' },
      { headerName: 'Nombre', field: 'name', sortable: true, filter: true, sort: 'asc', width: '310' },
      { headerName: 'Descripción', field: 'description', sortable: true, filter: true },
      { headerName: 'Precio', field: 'price.0.price', sortable: true, filter: true, width: '150' },
      { headerName: '% IVA', field: 'tax.0.percentage', sortable: true, filter: true, width: '100' },
      { headerName: 'IVA Alegra', field: 'tax.0.id', sortable: true, filter: true, width: '100' }

    ]
    this.mainTableHeaderGrid = [
      {
        headerName: 'Ventas',
        class: 'green',
        children: [
          {
            sortable: true,
            filter: true,
            checkboxSelection: true,
            columnGroupShow: 'open',
            headerName: 'Fecha',
            valueGetter: (params) => {
              const dates = params.data.sale.date.split('-')
              if (dates.length === 3) {
                return `${dates[2]} de ${this.months[parseInt(dates[1] - 1)]}`
              } else return params.data.sale.date
            }
          },
          // { headerName: 'Fecha', field: 'sale.date', sortable: true, filter: true, columnGroupShow: 'open', checkboxSelection: true },
          { headerName: 'Unidad', field: 'clientData.coproperty', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          // { headerName: 'Fecha de montaje', field: 'sale.mountingDate', sortable: true, filter: true, columnGroupShow: 'open' },
          {
            sortable: true,
            filter: true,
            columnGroupShow: 'open',
            headerName: 'Día de montaje',
            valueGetter: (params) => {
              const date = new Date(params.data.sale.mountingDate)
              const day = date.getDay()
              const month = date.getMonth()
              const dateDay = date.getDate()
              if (day >= 0) {
                return this.days[day] + ' ' + (dateDay + 1) + ' de ' + this.months[month]
              } else return params.data.sale.mountingDate
            },
            filterParams: { newRowsAction: 'keep' }
          },
          { headerName: 'Administrador', field: 'clientData.nameAdmin', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Teléfonos', field: 'clientData.phonePrimary', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Dirección', field: 'clientData.address', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Barrio', field: 'clientData.neighborhood', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Sector', field: 'clientData.sector', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } }
        ]
      },
      {
        headerName: 'Facturación',
        children: [
          { headerName: 'Remisión/Factura', field: 'billingData.invoiceRemision', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'V.Total', field: 'billingData.totalValue', sortable: true, filter: true, valueFormatter: currencyFormatter, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Anticipo', field: 'billingData.advancePayment', sortable: true, filter: true, valueFormatter: currencyFormatter, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Estado de pago', field: 'billingData.status', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } }
          /* {
            sortable: true,
            filter: true,
            columnGroupShow: 'open',
            headerName: 'Estado de Pago',
            width: 120,
            valueGetter: (params) => {
              return params.data.billingData.status ? 'Pago' : 'Debe'
            }
          } */
        ]
      },
      {
        headerName: 'Logística',
        children: [
          { headerName: 'Estado de pedido', field: 'logistic.status', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Separado', field: 'logistic.separated', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Agendado por', field: 'logistic.scheduleBy', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Conductor', field: 'logistic.team.operator1', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Decorador', field: 'logistic.team.operator2', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Montaje', field: 'logistic.mounting', sortable: true, filter: true, columnGroupShow: 'open' },
          { headerName: 'Desmontaje', field: 'logistic.dismounting', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Rotación', field: 'logistic.rotation', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Figuras', field: 'logistic.figures', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Heno', field: 'sale.heno', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Heno Adicional', field: 'sale.henoAdicional', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } },
          { headerName: 'Observaciones', field: 'logistic.observations', sortable: true, filter: true, columnGroupShow: 'open', filterParams: { newRowsAction: 'keep' } }
        ]
      }
    ]
    this.paginationPageSize = 10
    this.paginationPageSize1 = 7
    this.defaultColDef = { resizable: true }
    this.headerHeight = 30
    this.floatingFiltersHeight = 40
    // this.defaultColGroupDef = { class}
    var today = new Date()
    this.sale.date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    this.sale.dueDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() + 1)
  },
  data () {
    return {
      dataTemporal: [],
      filtroPagado: true,
      filtroPendiente: true,
      quitar: false,
      actionCollapse: true,
      modalShow: false,
      modalShow2: false,
      modalShow3: false,
      modalSelected: false,
      modalSelected2: false,
      modalSelected3: false,
      contProduct: 1,
      createdAt: '',
      nitFound: false,
      nit: '',
      docIdClient: '',
      client: {},
      json_fields: {
        Fecha: 'sale.date',
        Unidad: 'clientData.coproperty',
        FechaMontaje: 'sale.mountingDate',
        DiaDeMontaje: {
          campo: 'sale.mountingDate',
          callback: (valor) => {
            const date = new Date(valor.sale.mountingDate)
            const day = date.getDay()
            if (day >= 0) {
              return this.days[day]
            } else return 'N/A'
          }
        },
        Heno: 'sale.heno',
        'Heno Adicional': 'sale.henoAdicional',
        Plasticos: 'sale.plasticos',
        Country: 'sale.country',
        Complementos: 'sale.complementos',
        Administrador: 'clientData.nameAdmin',
        Teléfonos: 'clientData.phonePrimary',
        Dirección: 'clientData.address',
        Barrio: 'clientData.neighborhood',
        Sector: 'clientData.sector',
        Tipo: 'clientData.type',
        Email: 'clientData.email',
        Estado: 'logistic.status',
        Separado: 'logistic.separated',
        'Agendado Por': 'logistic.scheduleBy',
        Conductor: 'logistic.team.operator1',
        Decorador: 'logistic.team.operator2',
        Montaje: 'logistic.mounting',
        Desmontaje: 'logistic.dismounting',
        Rotación: 'logistic.rotation',
        Figuras: 'logistic.figures',
        Observaciones: 'logistic.observations',
        'Remisión/Factura': 'billingData.invoiceRemision',
        'V.Total': 'billingData.totalValue',
        Anticipo: 'billingData.advancePayment',
        'Estado de pago': 'billingData.status'
      },
      data: {
        idRemission: '',
        dataClient: {
          id: '',
          nit: '',
          dv: '',
          kindOfPerson: '',
          type: '',
          coproperty: '',
          nameAdmin: '',
          phonePrimary: '',
          email: '',
          country: 'Colombia',
          state: '',
          city: '',
          address: '',
          neighborhood: '',
          sector: '',
          client: ''
        },
        billingData: {
          totalValue: 0,
          advancePayment: 0,
          status: 'Debe',
          invoiceRemision: '',
          remisionID: ''
        },
        /* Esto lo debería de borrar
        billingData: {
          totalValue: 0,
          advancePayment: 0,
          status: false
        },
        Hasta aquí */
        logistic: {
          status: 'Pendiente',
          separated: '',
          team: {
            operator1: '',
            operator2: ''
          },
          scheduleBy: '',
          mounting: 0,
          dismounting: 0,
          rotation: 0,
          figures: 0,
          observations: ''
        },
        plasticos: '',
        country: '',
        complementos: '',
        heno: '',
        henoAdicional: '',
        informes: ''
      },
      sale: {
        plasticos: '',
        country: '',
        complementos: '',
        heno: '',
        henoAdicional: '',
        communicationChannel: '',
        informes: '',
        date: '',
        dueDate: '',
        mountingDate: '',
        client: '',
        warehouse: 2,
        items: []
      },
      item: {
        name: '',
        id: '',
        price: '',
        description: '',
        quantity: 1,
        iva: 0,
        tax: []
      },
      itemDefault: {
        name: '',
        id: '',
        price: '',
        description: '',
        quantity: 1,
        iva: 0,
        tax: []
      },
      typeClient: [
        { name: 'Cliente Antiguo', value: 'CA' },
        { name: 'Cliente Nuevo', value: 'CN' }
      ],
      typeDocument: [
        { text: 'NIT', value: 'NIT' },
        { text: 'CC', value: 'CC' }
      ],
      personType: [
        { text: 'Persona Jurídica', value: 'LEGAL_ENTITY' },
        { text: 'CC', value: 'PERSON_ENTITY)' }
      ],
      optionsSeparated: [
        { value: 'OK', text: 'OK' },
        { value: 'NO', text: 'NO' }
      ],
      optionsPayment: [
        { value: 'Pago', text: 'Pago' },
        { value: 'Debe', text: 'Debe' }
      ],
      optionsClient: [
        { value: 'Cliente Antiguo', text: 'Cliente Antiguo' },
        { value: 'Cliente nuevo', text: 'Cliente Nuevo' }
      ],
      optionsStatus: [
        { value: 'Pendiente', text: 'Pendiente' },
        { value: 'Completado', text: 'Completado' }
      ],
      optionsChanel: [
        { value: 'Llamada', text: 'Llamada' },
        { value: 'Whatsapp', text: 'Whatsapp' },
        { value: 'Correo', text: 'Correo' },
        { value: 'Sala de ventas', text: 'Sala de ventas' },
        { value: 'Visita', text: 'Visita' },
        { value: 'Tienda virtual', text: 'Tienda Virtual' }
      ],
      optionsInnerTeam: [
        { value: 'Diana V', text: 'Diana V' },
        { value: 'María C', text: 'María C' },
        { value: 'Andrea G', text: 'Andrea G' },
        { value: 'Yuliana V', text: 'Yuliana' },
        { value: 'Juliana O', text: 'Juliana O' },
        { value: 'Santiago O', text: 'Santiago O' },
        { value: 'Juan G', text: 'Juan G' },
        { value: 'Sebastián O', text: 'Sebastián O' }
      ],
      optionsTeam: [
        { value: 'Sigifredo', text: 'Sigifredo' },
        { value: 'Gino Ipolitpo', text: 'Gino' },
        { value: 'Mateo Díaz', text: 'Mateo' },
        { value: 'Alejandro Madrid', text: 'Alejandro Madrid' },
        { value: 'Nayiver Guzmán', text: 'Nayiver Guzmán' },
        { value: 'Yohana Rendón', text: 'Yohana Rendón' }

      ],
      collapse1: true,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      nitSearched: null,
      tableHeaderGrid: [],
      tableDataGrid: [],
      tableHeaderGrid1: [],
      tableDataGrid1: [],
      mainTableHeaderGrid: [],
      mainTableDataGrid: [],
      headerHeight: null,
      floatingFiltersHeight: null,
      productsMap: {},
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultColGroupDef: null,
      paginationPageSize: null,
      modules: AllCommunityModules,
      modal: false,
      aaaid: null,
      dataMap: {},
      counterPrueba: 0, // se puede borrar
      productsSelected: [],
      productToDelete: null,
      dealToDelete: {
        doc: '',
        remisionID: '',
        number: ''
      },
      canDelete: false,
      days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    }
  },
  firestore () {
    return {
      mainTableDataGrid: db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').orderBy('createdAt', 'asc')
    }
  },
  components: {
    AgGridVue
  },
  methods: {
    currencyFormatterCop (value) {
      return '\x24' + formatNumber(value)
    },
    filtrarPagos () {
      this.filtroPendiente = false
      this.filtroPagado = true
      this.quitar = true
      if (this.dataTemporal.length >= this.mainTableDataGrid) {
        const dataFilter = this.dataTemporal.filter(item => {
          return item.logistic.status === 'Completado'
        })
        this.mainTableDataGrid = dataFilter
      } else {
        this.dataTemporal = this.mainTableDataGrid
        const dataFilter = this.mainTableDataGrid.filter(item => {
          return item.logistic.status === 'Completado'
        })
        this.mainTableDataGrid = dataFilter
      }
    },
    filtrarPendientes () {
      this.filtroPendiente = true
      this.filtroPagado = false
      this.quitar = true
      if (this.dataTemporal.length >= this.mainTableDataGrid) {
        const dataFilter = this.dataTemporal.filter(item => {
          return item.logistic.status === 'Pendiente'
        })
        this.mainTableDataGrid = dataFilter
      } else {
        this.dataTemporal = this.mainTableDataGrid
        const dataFilter = this.mainTableDataGrid.filter(item => {
          return item.logistic.status === 'Pendiente'
        })
        this.mainTableDataGrid = dataFilter
      }
    },
    quitarFiltros () {
      this.filtroPendiente = true
      this.filtroPagado = true
      this.quitar = false
      this.mainTableDataGrid = this.dataTemporal
      this.dataTemporal = []
    },
    async deleteDeal (alegra) {
      if (alegra) {
        await axios({
          method: 'delete',
          url: 'https://api.alegra.com/api/v1/remissions/' + this.dealToDelete.remisionID,
          headers: { Authorization: basicAuth }
        }).then(res => {
          console.log(res)
          this.makeToast('success', 'La remisión ' + this.dealToDelete.number + ' ha sido eliminada de Alegra')
          this.deleteDealSistevot()
        }).catch((error) => {
          this.makeToast('danger', 'Alegra no ha eliminado la remisión. ' + error)
        })
      } else { this.deleteDealSistevot() }
      this.canDelete = false
    },
    async deleteDealSistevot () {
      await db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').doc(this.dealToDelete.doc).delete()
        .then(() => {
          this.makeToast('success', 'Pedido eliminado de Sistevot')
          this.modalShow = false
          this.modalSelected = false
        }).catch((error) => {
          this.makeToast('danger', 'El pedido no ha sido eliminado de Sistevot' + error)
        })
      this.canDelete = false
    },
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    clearProducts () {
      this.tableDataGrid = []
      this.sale.items = []
      /* for (let index = 0; index < this.productsSelected.length; index++) {
        var productToDelete = this.tableDataGrid[this.productsSelected[index]]
        console.log('Producto #', index, productToDelete) */
      this.data.billingData.totalValue = 0
      this.item = {
        name: '',
        id: '',
        price: '',
        description: '',
        quantity: 1,
        iva: 0,
        tax: []
      }
      // this.tableDataGrid.splice(productsSelected[index])
    },
    clearProductssingle () {
      if (this.productToDelete) {
        console.log()
        const iva = (this.sale.items[this.productToDelete].iva / 100) * this.sale.items[this.productToDelete].price * this.sale.items[this.productToDelete].quantity
        const total = (parseInt(((this.sale.items[this.productToDelete].price * this.sale.items[this.productToDelete].quantity) + iva)))
        console.log('el total a descontar es:', total)
        this.data.billingData.totalValue -= total
        this.tableDataGrid.splice(this.productToDelete, 1)
        this.sale.items.splice(this.productToDelete, 1)
        this.productToDelete = null
      }
    },
    clear () {
      // Esta guachada es provisional
      // window.location.reload()
      this.data = {
        idRemission: '',
        modalShow: false,
        modalShow2: false,
        modalShow3: false,
        modalSelected: false,
        modalSelected2: false,
        modalSelected3: false,
        dataClient: {
          id: '',
          nit: '',
          dv: '',
          type: '',
          kindOfPerson: '',
          coproperty: '',
          nameAdmin: '',
          phonePrimary: '',
          email: '',
          country: 'Colombia',
          state: '',
          city: '',
          address: '',
          neighborhood: '',
          sector: '',
          client: ''
        },
        billingData: {
          totalValue: 0,
          advancePayment: 0,
          status: 'Debe',
          invoiceRemision: '',
          remisionID: ''
        },
        logistic: {
          status: 'Pendiente',
          separated: '',
          team: {
            operator1: '',
            operator2: ''
          },
          scheduleBy: '',
          mounting: 0,
          dismounting: 0,
          rotation: 0,
          figures: 0,
          observations: ''
        }
      }
      this.sale = {
        plasticos: '',
        country: '',
        complementos: '',
        heno: '',
        henoAdicional: '',
        communicationChannel: '',
        informes: '',
        date: '',
        dueDate: '',
        mountingDate: '',
        client: '',
        warehouse: 2,
        items: []
      }
      this.item = {
        name: '',
        id: '',
        price: '',
        description: '',
        quantity: 1,
        iva: 0,
        tax: []
      }
      var today = new Date()
      this.sale.date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.sale.dueDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() + 1)
      this.nitFound = false
      this.nit = ''
      this.docIdClient = ''
      this.client = {}
      this.aaaid = null
      this.dataMap = {}
      this.productsMap = {}
      this.tableDataGrid = []
      this.collapse1 = true
      this.collapse2 = false
      this.collapse3 = false
      this.collapse4 = false
      this.productsSelected = []
      this.productToDelete = null
      this.dealToDelete = {
        doc: '',
        remisionID: '',
        number: ''
      }
      this.canDelete = false
    },
    onRowSelectedMain (event) {
      if (!event.node.selected) {
        return
      }
      this.dealToDelete.name = event.node.data.clientData.coproperty
      this.dealToDelete.doc = event.node.data.id
      this.dealToDelete.remisionID = event.node.data.billingData.remisionID
      this.dealToDelete.number = event.node.data.billingData.invoiceRemision
      console.log(this.dealToDelete.doc)
      console.log(this.dealToDelete.remisionID)
    },
    onSelectionChangedMain (event) {
      var rowCount = event.api.getSelectedNodes().length
      console.log(rowCount)
      if (rowCount >= 1) {
        this.canDelete = true
      } else {
        this.canDelete = false
      }
    },
    onRowSelected (event) {
      if (!event.node.selected) {
        return
      }
      const id = event.node.id
      console.log('Selected row: ' + id)
      this.productToDelete = id
    },
    onSelectionChanged (event) {
      // var rowCount = event.api.getSelectedNodes().length
      // window.alert('selection changed, ' + rowCount + ' rows selected')
    },
    async next (actual) {
      if (actual === 1) {
        const valid = await (this.$refs.observerCollapse1.validate())
        if (valid) {
          this.collapse1 = false
          this.collapse2 = true
        } else core.showSnackbar('error', 'Debe completar los campos requeridos')
      } else {
        if (actual === 2) {
          this.collapse2 = false
          this.collapse3 = true
        } else {
          this.collapse3 = false
          this.collapse4 = true
        }
      }
    },
    sizeToFit () {
      this.gridApi.sizeColumnsToFit()
    },
    autoSizeAll (skipHeader) {
      var allColumnIds = []
      this.gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId)
      })
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
    },
    onRowClicked (params) {
      const taxID = params.node.data.tax[0]
      console.log(params)
      console.log(params.node.data)
      this.item.name = params.node.data.name
      this.item.id = params.node.data.id
      this.item.description = params.node.data.description
      this.item.price = parseInt(params.node.data.price[0].price)
      if (params.node.data.tax.length >= 1) {
        this.item.iva = parseInt(params.node.data.tax[0].percentage)
        this.item.tax.splice(0, 1, taxID)
      } else {
        this.item.iva = 0
        this.item.tax = []
      }
    },
    onRowClicked2 (params) {
      console.log(params.node)
      this.clear()
      this.filterItem()
      console.log('Esto es el Table data grid', params.node.data.sale.items)
      for (let index = 0; index < params.node.data.sale.items.length; index++) {
        this.tableDataGrid.push(params.node.data.sale.items[index])
      }
      this.aaaid = params.node.data.id
      this.data.dataClient = params.node.data.clientData
      this.data.billingData = params.node.data.billingData
      this.data.logistic = params.node.data.logistic
      this.sale = params.node.data.sale
      // this.item.id = params.node.data.id
      // this.item.price = parseInt(params.node.data.price[0].price)
      // this.item.iva = parseInt(params.node.data.tax[0].percentage)
      this.modal = true
      this.nitFound = true
      this.consultClient()
    },
    async consultClient () {
      await db.collectionGroup('contacts')
        .where('identificationObject.number', '==', this.data.dataClient.nit)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            this.nitFound = true
            querySnapshot.forEach((doc) => {
              this.docIdClient = doc.id
            })
          } else {
            // TODO: Debo mostar una alerta informativa
            this.nitFound = false
          }
        })
    },
    /**
     * Metodo para agregar un item a la lista y visualizarlo en la tabla
     */
    async addItem () {
      this.counterPrueba++
      console.log(this.counterPrueba + 'vez que entro al metodo')
      const valid = await (this.$refs.observer2.validate())
      if (valid) {
        const iva = (this.item.iva / 100) * this.item.price * this.item.quantity
        this.data.billingData.totalValue += parseInt(((this.item.price * this.item.quantity) + iva))
        const itemAdded = {}
        /* No se esto por que itemAdded.id = this.contProduct++ */
        itemAdded.id = this.item.id
        itemAdded.name = this.item.name
        itemAdded.price = parseFloat(this.item.price)
        itemAdded.description = this.item.description
        itemAdded.quantity = parseFloat(this.item.quantity)
        itemAdded.iva = parseInt(this.item.iva)
        itemAdded.tax = this.item.tax
        console.log(itemAdded)
        // Eliminar los null
        Object.keys(itemAdded).forEach(function (key) {
          if (itemAdded[key] === null || itemAdded[key] === undefined) {
            itemAdded[key] = '-'
          }
        })
        this.tableDataGrid.push(itemAdded)
        this.sale.items.push(itemAdded)
        this.item = Object.assign({}, this.itemDefault)
        this.$refs.observer2.reset()
      } else {
        console.log('no agregar')
      }
    },
    /**
     * Metodo para guardar la informacion final
     */
    async handleOk () {
      const valid = await (this.$refs.observer.validate())
      console.log('here')
      console.log(valid)
      if (valid) {
        // this.$refs.observer2.reset()
        console.log()
      } else {
        // this.$refs.observer2.reset()
      }
      // const basicAuth = 'Basic Yml3ZXIyNThAZ21haWwuY29tOjZiMjkyNzMyMzJhZmQyOTU3MWU4'
      // axios({
      //   method: 'post',
      //   url: 'https://api.alegra.com/api/v1/remissions/',
      //   data: this.sale,
      //   headers: { Authorization: basicAuth }
      // }).then(res => {
      //   console.log(res)
      // }).catch(error => {
      //   console.log(error)
      // })
    },
    /**
     * Metodo que filtra los datos de un cliente por nit
     */
    async filterClient () {
      const valid = await this.$refs.observer3.validate()
      if (valid) {
        await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .where('identificationObject.number', '==', this.nit)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              this.nitFound = true
              querySnapshot.forEach((doc) => {
                this.nitSearched = doc
                var obj = {}
                console.log('el nit es', this.nitSearched.data().address.city)
                this.docIdClient = doc.id
                this.client = doc.data
                this.sale.client = parseInt(doc.data().id)
                // *************Formateo de informacion
                obj.country = doc.data().address.country
                obj.state = doc.data().address.department
                obj.city = doc.data().address.city
                obj.nit = this.nit
                obj.dv = doc.data().identificationObject.dv
                obj.type = doc.data().identificationObject.type
                if (doc.data().identificationObject.type === 'CC') {
                  obj.kindOfPerson = 'PERSON_ENTITY'
                } else if (doc.data().identificationObject.type === 'NIT') {
                  obj.kindOfPerson = 'LEGAL_ENTITY'
                }
                obj.email = doc.data().email
                obj.id = doc.data().id
                obj.coproperty = doc.data().name
                obj.nameAdmin = doc.data().nameAdmin
                obj.phonePrimary = doc.data().phonePrimary
                obj.address = doc.data().address.address
                obj.neighborhood = doc.data().address.neighborhood
                obj.sector = doc.data().address.sector
                obj.client = doc.data().clientType
                // Proceso para eliminar los null
                Object.keys(obj).forEach(function (key) {
                  if (obj[key] === null || obj[key] === undefined) {
                    obj[key] = '-'
                  }
                })
                this.data.dataClient = obj
              })
            } else {
              // TODO: Debo mostar una alerta informativa
              this.makeToast('danger', 'Contacto no encontrado')
              this.nitFound = false
            }
          })
      } else {
        console.log(this.data.dataClient.nit)
      }
    },
    async filterItem () {
      console.log('inicio el metodo Index')
      await db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/items/')
        .doc('aaatodoslositems')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            this.itemsFound = true
            let data = {}
            data = querySnapshot.data()
            this.tableDataGrid1 = Object.values(data)
          } else {
            // TODO: Debo mostar una alerta informativa
            console.log('Esta vacio')
            this.itemsFound = false
          }
        })
    },
    async filterItem_cambiojuan () {
      console.log('inicio el metodo')
      await db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/items')
        .where('name', '==', 'Servicio de Desmontaje')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            this.itemsFound = true
            querySnapshot.forEach((doc) => {
              this.tableDataGrid1.push(doc.data())
              this.dataMap[doc.id] = doc.data()
              this.addToIndex()
            })
          } else {
            // TODO: Debo mostar una alerta informativa
            console.log('Esta re vacio')
            this.itemsFound = false
          }
        })
    },
    async createDataClient () {
      var proceed = false
      if (this.data.dataClient.type === 'CC') {
        this.data.dataClient.kindOfPerson = 'PERSON_ENTITY'
      }
      if (this.data.dataClient.type === 'NIT') {
        this.data.dataClient.kindOfPerson = 'LEGAL_ENTITY'
      }
      // CREA LOS DATOS DEL CONTACTO EN ALEGRA - KEY BIWER259 Yml3ZXIyNThAZ21haWwuY29tOjZiMjkyNzMyMzJhZmQyOTU3MWU4
      const dataToAlegra = {
        name: this.data.dataClient.coproperty,
        phonePrimary: this.data.dataClient.phonePrimary,
        email: this.data.dataClient.email,
        // ignoreRepeated: false,
        identificationObject: {
          number: this.data.dataClient.nit,
          type: this.data.dataClient.type,
          dv: this.data.dataClient.dv,
          kindOfPerson: 'LEGAL_ENTITY'
        },
        address: {
          address: this.data.dataClient.address,
          city: this.data.dataClient.city,
          country: this.data.dataClient.country,
          department: this.data.dataClient.state
        }
      }
      console.log(dataToAlegra)
      await axios({
        method: 'post',
        url: 'https://api.alegra.com/api/v1/contacts/',
        data: dataToAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log(res)
        this.data.dataClient.id = res.data.id
        proceed = true
        this.makeToast('success', 'Contacto creado en Alegra')
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha actualizado el contacto. ' + error)
      })
      // CREA LOS DATOS DEL CONTACTO EN CONTACT DE FIREBASE
      if (proceed) {
        db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/contacts/').add({
          address: {
            address: this.data.dataClient.address,
            city: this.data.dataClient.city,
            country: this.data.dataClient.country,
            department: this.data.dataClient.state,
            neighborhood: this.data.dataClient.neighborhood,
            sector: this.data.dataClient.sector
          },
          name: this.data.dataClient.coproperty,
          nameAdmin: this.data.dataClient.nameAdmin,
          email: this.data.dataClient.email,
          phonePrimary: this.data.dataClient.phonePrimary,
          clientType: this.data.dataClient.client,
          // name: this.data.dataClient.name,
          id: this.data.dataClient.id,
          identificationObject: {
            number: this.data.dataClient.nit,
            type: this.data.dataClient.type,
            dv: this.data.dataClient.dv
          }
        }).then((res) => {
          this.makeToast('success', 'Contacto creado en Sistevot')
        }).catch((error) => {
          console.error(error)
          this.makeToast('danger', 'Sistevot no ha creado el contacto' + error)
        })
        if (this.aaaid) {
          db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween')
            .doc(this.aaaid).update({
              clientData: this.data.dataClient
            }).then((docRef) => {
            })
        }
      }
    },
    async updateDataClient () {
      var proceed = false
      // ACTUALIZA LOS DATOS DEL CONTACTO EN ALEGRA - KEY BIWER259 Yml3ZXIyNThAZ21haWwuY29tOjZiMjkyNzMyMzJhZmQyOTU3MWU4
      const dataToAlegra = {
        id: this.data.dataClient.id,
        name: this.data.dataClient.coproperty,
        phonePrimary: this.data.dataClient.phonePrimary,
        email: this.data.dataClient.email,
        // ignoreRepeated: false,
        identificationObject: {
          number: this.data.dataClient.nit,
          type: this.data.dataClient.type,
          dv: this.data.dataClient.dv
        },
        address: {
          address: this.data.dataClient.address,
          city: this.data.dataClient.city,
          country: this.data.dataClient.country,
          department: this.data.dataClient.state
        }
      }
      console.log('dataToAlegra', dataToAlegra)
      await axios({
        method: 'put',
        url: 'https://api.alegra.com/api/v1/contacts/?' + this.data.dataClient.id,
        data: dataToAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log(res)
        proceed = true
        this.makeToast('success', 'Contacto actualizado en Alegra')
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha actualizado el contacto. ' + error)
      })
      // ACTUALIZA LOS DATOS DEL CONTACTO EN CONTACT DE FIREBASE
      if (proceed) {
        db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/contacts/').doc(this.docIdClient).update({
          address: {
            address: this.data.dataClient.address,
            city: this.data.dataClient.city,
            country: this.data.dataClient.country,
            department: this.data.dataClient.state,
            neighborhood: this.data.dataClient.neighborhood,
            sector: this.data.dataClient.sector
          },
          name: this.data.dataClient.coproperty,
          nameAdmin: this.data.dataClient.nameAdmin,
          email: this.data.dataClient.email,
          phonePrimary: this.data.dataClient.phonePrimary,
          clientType: this.data.dataClient.client,
          // name: this.data.dataClient.name,
          id: this.data.dataClient.id,
          identificationObject: {
            number: this.data.dataClient.nit,
            type: this.data.dataClient.type,
            dv: this.data.dataClient.dv
          }
        // ACTUALIZA LOS DATOS DEL CONTACTO EN SISTEVOT
        }).then(res => {
          this.makeToast('success', 'Contácto actualizado en Sistevot')
          if (this.aaaid) {
            db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween')
              .doc(this.aaaid).update({
                clientData: this.data.dataClient
              }).then((docRef) => {
                this.makeToast('success', 'Pedido actualizado en Sistevot')
              }).catch(error => {
                console.log(error)
                this.makeToast('danger', 'El pedido no fue actualizado en Sistevot. ' + error)
              })
          }
        }).catch((error) => {
          this.makeToast('danger', 'Sistevot no ha actualizado el contacto. ' + error)
        })
      }
    },
    axiosTest () {
      // const username = 'biwer258@gmail.com'
      axios.get('https://api.alegra.com/api/v1/contacts/', {
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log(res)
      })
    },
    async saveDeal () {
      this.createdAt = Date.now()
      await db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').add({
        createdAt: this.createdAt,
        clientData: this.data.dataClient,
        billingData: this.data.billingData,
        sale: this.sale,
        logistic: this.data.logistic
      }
      ).then((docRef) => {
        console.log('Document written with ID:', docRef.id)
        this.makeToast('success', 'Pedido creado en Sistevot')
        this.modal = false
      }).catch(error => {
        console.log(error)
        this.makeToast('danger', 'El pedido no fue creado en Sistevot. ' + error)
      })
    },
    async saveDealRemision (alegra) {
      // var proceed = false
      this.createdAt = Date.now()
      if (alegra) {
        await axios({
          method: 'post',
          url: 'https://api.alegra.com/api/v1/remissions/',
          data: this.sale,
          headers: { Authorization: basicAuth }
        }).then(res => {
          this.data.billingData.invoiceRemision = res.data.number
          this.data.billingData.remisionID = res.data.id
          this.saveDeal()
          console.log(res)
          this.makeToast('success', 'Remisión creada en Alegra')
        }).catch(error => {
          console.log(error)
          this.makeToast('danger', 'La remisión no fue creada en Alegra. ' + error)
        })
      } else { this.saveDeal() }
      this.modalShow2 = false
      this.modalSelected2 = false
      /* if (proceed) {
        db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').add({
          createdAt: this.createdAt,
          clientData: this.data.dataClient,
          billingData: this.data.billingData,
          sale: this.sale,
          logistic: this.data.logistic
        }
        ).then((docRef) => {
          console.log('Document written with ID:', docRef.id)
          this.makeToast('success', 'Pedido creado en Sistevot')
          this.modal = false
        }).catch(error => {
          console.log(error)
          this.makeToast('danger', 'El pedido no fue creado en Sistevot. ' + error)
        })
      } */
    },
    async updateDeal () {
      console.log('Actualizar remisión con los datos:', this.sale)
      await db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').doc(this.aaaid).update({
        clientData: this.data.dataClient,
        billingData: this.data.billingData,
        sale: this.sale,
        logistic: this.data.logistic
      }
      ).then((docRef) => {
        console.log('Document written with ID:')
        this.makeToast('success', 'Pedido actualizado en Sistevot')
        this.modal = false
      }).catch((error) => {
        this.makeToast('danger', 'El pedido no fue actualizado en Sistevot' + error)
      })
    },
    async updateDealRemision (alegra) {
      // var proceed = false
      console.log('Actualizar remisión con los datos:', this.sale)
      if (alegra) {
        await axios({
          method: 'put',
          url: 'https://api.alegra.com/api/v1/remissions/:' + this.data.billingData.invoiceRemision,
          data: {
            id: this.data.billingData.remisionID,
            items: this.sale.items,
            date: this.sale.date,
            dueDate: this.sale.dueDate,
            client: this.sale.client
          },
          headers: { Authorization: basicAuth }
        }).then(res => {
          // proceed = true
          this.updateDeal()
          console.log('Lo que devuelve Alegra', res)
          this.makeToast('success', 'Remisión actualizada en Alegra')
        }).catch(error => {
          console.log(error)
          this.makeToast('danger', 'La remisión no fue actualizada en Alegra. ' + error)
        })
      } else { this.updateDeal() }
      this.modalShow3 = false
      /* if (proceed) {
        db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/aaaHalloween').doc(this.aaaid).update({
          clientData: this.data.dataClient,
          billingData: this.data.billingData,
          sale: this.sale,
          logistic: this.data.logistic
        }
        ).then((docRef) => {
          console.log('Document written with ID:')
          this.makeToast('success', 'Pedido actualizado en Sistevot')
          this.modal = false
        }).catch((error) => {
          this.makeToast('danger', 'El pedido no fue actualizado en Sistevot' + error)
        })
      } */
    },
    async addToIndex () {
      await db.collection('/co-properties/AxHgiLs9ob5f7MFfNYgt/items')
        .doc('aaatodoslositems')
        .set(this.dataMap, { merge: true })
        .then(() => {
          console.log('Document successfully written!')
        })
        .catch((error) => {
          console.error('Error de escritura', error)
        })
      console.log('Prueba2 DataMap')
    },
    onBtStartEditing (key, char, pinned) {
      this.gridApi.setFocusedCell(0, 'lastName', pinned)
      this.gridApi.startEditingCell({
        rowIndex: 0,
        colKey: 'lastName',
        rowPinned: pinned,
        keyPress: key,
        charPress: char
      })
    }
  }
}
</script>
<style scoped>
.button-filtro-pendiente{
  background-color: #e43838;
  border-color: #e43838;
}

.button-filtro-excel{
  background-color: #316b38;
  border-color: #316b38;
}
</style>
